import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

class Food extends React.Component {

    constructor(props) {
        super(props);
        this._isMounted = false;
        this.state = {
            locations: [],
            tempLocations: [],
            isLoaded: false
        };
        this.updateFoodList = this.updateFoodList.bind(this);
        this.callback = this.callback.bind(this);
        this.callbackDistance = this.callbackDistance.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        window.scrollTo(0, 0);
        document.title = "Muslim Life - Halal Food";
        if (this.props.lat != '' && this.props.long != '') {
            this.initialize();
        }
        else {
            alert("Please enable location to see halal food near you.");
            this.setState(
                { isLoaded: true }
            );
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
     }

    // componentDidUpdate(prevProps) {
    //     if (this.props.type != prevProps.type) // Check if it's a new user, you can also use some unique property, like the ID  (this.props.user.id !== prevProps.user.id)
    //     {
    //         this.setState(
    //             { locations: [], isLoaded: false, tempLocations: [] },
    //             () => this.initialize()
    //         );
    //     }
    // }

    initialize() {
        var map;
        var service;

        var location = new window.google.maps.LatLng(this.props.lat, this.props.long);

        map = new window.google.maps.Map(document.getElementById("map"), {
            center: location,
            zoom: 15
        });

        var request = {
            location: location,
            radius: '32186',
            type: ["restaurant"],
            keyword: "halal",
            openNow: true
        };

        service = new window.google.maps.places.PlacesService(map);
        service.nearbySearch(request, this.callback);
    }

    //destinations
    //allFoods

    callback(results, status, pagination) {
        var destinations = [];
        if (status == window.google.maps.places.PlacesServiceStatus.OK) {
            for (var i = 0; i < results.length; i++) {
                var item = results[i];
                var destination = new window.google.maps.LatLng(item.geometry.location.lat(), item.geometry.location.lng());
                destinations.push(destination);
            }
            //sessionStorage.setItem("tempLocations", JSON.stringify(results));
            this._isMounted && this.setState(
                { tempLocations: results, isLoaded: true },
                () => this.distanceCalc(destinations)
            );
        }
        else {
            alert("Failed to retrieve results. Please makes sure location access is allowed.");
            this.setState(
                { isLoaded: true }
            );
        }

        if (pagination.hasNextPage) {
            pagination.nextPage();
        }

    }

    distanceCalc(destinations) {
        var origin1 = new window.google.maps.LatLng(this.props.lat, this.props.long);
        var service = new window.google.maps.DistanceMatrixService();

        service.getDistanceMatrix(
            {
                origins: [origin1],
                destinations: destinations,
                travelMode: 'DRIVING',
                unitSystem: window.google.maps.UnitSystem.IMPERIAL
                // drivingOptions: {
                //     departureTime: new Date(Date.now())
                // } there is no traffic during corona. turn this on later.
            }, this.callbackDistance);

    }

    callbackDistance(response, status) {
        // See Parsing the Results for
        // the basics of a callback function.
        //var allFoods = JSON.parse(sessionStorage.getItem("tempLocations"));
        var allFoods = this.state.tempLocations;
        if (status == 'OK') {
            var origins = response.originAddresses;

            for (var i = 0; i < origins.length; i++) {
                var results = response.rows[i].elements;
                for (var j = 0; j < results.length; j++) {
                    var element = results[j];
                    allFoods[j].distance = element.distance.text;
                    //allFoods[j].duration = element.duration_in_traffic.text;
                    allFoods[j].duration = element.duration.text;
                }
            }

            this.updateFoodList(allFoods);
        }
        else {
            alert("Failed to retrieve results");
            // document.getElementById("loader").style.display = "none";
            // document.getElementById("myDiv").style.display = "block";
        }
    }

    updateFoodList(allFoods) {
        let arrayLength = allFoods.length;
        var currLocations = [];
        for (let i = 0; i < arrayLength; i++) {
            var food = {};
            var item = allFoods[i];
            food.name = item["name"];
            food.address = item["vicinity"];
            food.rating = item["rating"];
            var contactInfo = item["name"].replace(" ", "+") + " " + item["vicinity"].replace(" ", "+");
            food.contact = `https://www.google.com/maps?q=${contactInfo}`
            food.distance = item.distance;
            food.duration = item.duration;
            var location = {
                'name': food.name,
                'rating': food.rating,
                'address': food.address,
                'distance': food.distance,
                'duration': food.duration,
                'contact': food.contact
            }
            currLocations.push(location);
        }

        this._isMounted && this.setState(function (prevState, props) {
            var allLocations = prevState.locations.concat(currLocations);
            return { locations: allLocations }
        });

        // try {
        //   if (html) {
        //     sessionStorage.setItem("mosqHTML", html);
        //   }
        // }
        // catch (err) {
        //   console.log("Couldnt store html data, RIP google api requests");
        // }

    }

    /*An array containing all the city names in the USA:*/

    /*initiate the autocomplete function on the "cityText" element, and pass along the countries array as possible autocomplete values:*/
    // window.onload = function () {

    //   if (sessionStorage.getItem("mosqHTML") != null) {
    //     document.getElementById('allFoodInfo').innerHTML = sessionStorage.getItem("mosqHTML");
    //     document.getElementById("loader").style.display = "none";
    //     document.getElementById("myDiv").style.display = "block";
    //   }
    //   else {
    //     getLocation();
    //   }
    // }

    render() {
        if (!this.state.isLoaded)
            return (
                <div>
                    <h2 style={{ textAlign: 'center', marginTop: '15px' }}>Halal Food Near You حلال</h2>
                    <div id="center" style={{ position: 'fixed', top: '50%', left: '50%' }}>
                        <div className="spinner-border" role="status" id="loader">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>
            );
        return (
            <div>
                {/* <div className="text-center">
          <div className="spinner-border" role="status" id="loader">
            <span className="sr-only">Loading...</span>
          </div>
        </div> */}
                <div id="myDiv" className="animate-bottom">
                    <h2 style={{ textAlign: 'center', marginTop: '15px' }}>Halal Food Near You حلال</h2>
                    <div className="container" id="allFoodInfo">
                        {this.state.locations.map((item, idx) => (
                            <div className="card" key={idx}>
                                <div className="card-body">
                                    <h4 className="card-title">{this.state.locations[idx].name}</h4>
                                    <p className="card-text">Rating: {this.state.locations[idx].rating} ⭐</p>
                                    <p className="card-text">Address: {this.state.locations[idx].address} 📍</p>
                                    <p className="card-text">Distance: {this.state.locations[idx].distance} 📏</p>
                                    <p className="card-text">Commute: {this.state.locations[idx].duration} 🚗</p>
                                    <a className="card-text" rel="noopener noreferrer" href={this.state.locations[idx].contact} target="_blank">Open in Google Maps</a>
                                    <p className="card-text" style={{fontSize : "60%"}}>* Verify halal status with restaurant *</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    };
};

export default Food;
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Nav, Navbar } from 'react-bootstrap';
import { NavLink } from 'react-router-dom'
import mosqueIcon from './mosque.svg';
//import mosqueIcon from '@iconify/icons-emojione/mosque';


class Header extends React.Component {
    render() {
        return (
            <Navbar sticky="top" bg="dark" variant="dark" expand="md" collapseOnSelect="true">
                <Navbar.Brand href="/">
                    <img
                        src={mosqueIcon}
                        alt="Muslim Life Logo"
                        width="30"
                        height="30"
                        className="d-inline-block align-top"
                    />{' '}
                    Muslim Life
                </Navbar.Brand>
                {/* <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExample04" aria-controls="navbarsExample04" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon" />
                </button> */}
                <Navbar.Toggle aria-controls="realislam-navbar-nav"></Navbar.Toggle>
                <Navbar.Collapse id="realislam-navbar-nav">
                    <Nav 
                    className="mr-auto"
                    >
                    <Nav.Item>
                    <NavLink exact to="/"><Nav.Link href="/" as="div">Prayer Times</Nav.Link></NavLink>
                    </Nav.Item>
                    <Nav.Item>
                    <NavLink exact to="/quran"><Nav.Link href="/quran" as="div">Quran</Nav.Link></NavLink>
                    </Nav.Item>
                    <Nav.Item>
                    <NavLink exact to="/quranschedule"><Nav.Link href="/quranschedule" as="div">Quran Schedule</Nav.Link></NavLink>
                    </Nav.Item>
                    <Nav.Item>
                    <NavLink exact to="/mosques"><Nav.Link href="/mosques" as="div">Mosques</Nav.Link></NavLink>
                    </Nav.Item>
                    <Nav.Item>
                    <NavLink exact to="/halalfood"><Nav.Link href="/halalfood" as="div">Halal Food</Nav.Link></NavLink>
                    </Nav.Item>
                    <Nav.Item>
                    <NavLink exact to="/contact"><Nav.Link href="/contact" as="div">Contact Us</Nav.Link></NavLink>
                    </Nav.Item>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        );
    }
};

export default Header;
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

class Contact extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            message: ''
        }
    }

    componentDidMount() {
        document.title = "Muslim Life - Contact Us";
    }

    render() {
        return (
            <div className="Contact container" style={{ padding: '15px' }}>
                <h2 style={{ textAlign: 'center' }}>Contact Us 📧</h2>
                <form id="contact-form" action="https://formspree.io/xknqnpea" method="POST">
                    <div className="form-group">
                        <label htmlFor="name">Name</label>
                        <input name="user_name" type="text" className="form-control" value={this.state.name} onChange={this.onNameChange.bind(this)} required/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Email address</label>
                        <input name="user_mail" type="email" className="form-control" aria-describedby="emailHelp" value={this.state.email} onChange={this.onEmailChange.bind(this)} required/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="message">Message</label>
                        <textarea name="user_message" className="form-control" rows="5" value={this.state.message} onChange={this.onMessageChange.bind(this)} required/>
                    </div>
                    <input type="hidden" name="_next" value="https://muslimlife.us/" />
                    <button type="submit" className="btn btn-primary">Submit</button>
                </form>
            </div>
        );
    }

    onNameChange(event) {
        this.setState({ name: event.target.value })
    }

    onEmailChange(event) {
        this.setState({ email: event.target.value })
    }

    onMessageChange(event) {
        this.setState({ message: event.target.value })
    }

    // emailIsValid (email) {
    //     return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
    // }

    // onClick(event) {
    //     event.preventDefault();

    //     if (!this.emailIsValid(this.state.email)) {
    //         alert("Please enter a valid email address");
    //         return null;
    //     }

    //     if (this.state.name === "" || this.state.name === null) {
    //         alert("Please enter your name");
    //         return null;
    //     }

    //     if (this.state.message === "" || this.state.message === null) {
    //         alert("Please enter your message");
    //         return null;
    //     }

    //     const data = { name: this.state.name , email: this.state.email, message: this.state.message };

    //     fetch('https://formspree.io/xknqnpea', {
    //     method: 'POST', // or 'PUT'
    //     headers: {
    //         'Content-Type': 'application/json',
    //     },
    //     body: JSON.stringify(data),
    //     })
    //     .then(response => response.json())
    //     .then(data => {
    //     console.log(data);
    //     alert("Thank you for your feedback! We will aim to get back to you very soon!");
    //     })
    //     .catch((error) => {
    //     console.error('Error:', error);
    //     });
    // }

}

export default Contact;

// // Can't send an empty form
// // Make sure you have placed the "name" attribute in all your form elements. Also, to prevent empty form submissions, take a look at the "required" property.

// // This error also happens when you have an "enctype" attribute set in your <form>, so make sure you don't.
// import React, { useState } from 'react';
// import { Form, Col, InputGroup, Button } from 'react-bootstrap';

// function Contact() {
//     const [validated, setValidated] = useState(false);

//     const handleSubmit = (event) => {
//       const form = event.currentTarget;
//       if (form.checkValidity() === false) {
//         event.preventDefault();
//         event.stopPropagation();
//       }

//       setValidated(true);
//     };

//     return (
//       <Form noValidate validated={validated} onSubmit={handleSubmit}>
//         <Form.Row>
//           <Form.Group as={Col} md="4" controlId="validationCustom01">
//             <Form.Label>First name</Form.Label>
//             <Form.Control
//               required
//               type="text"
//               placeholder="First name"
//               defaultValue="Mark"
//             />
//             <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
//           </Form.Group>

//         </Form.Row>
//         <Form.Row>
//           <Form.Group as={Col} md="4" controlId="validationCustom03">
//             <Form.Label>City</Form.Label>
//             <Form.Control type="text" placeholder="City" required />
//             <Form.Control.Feedback type="invalid">
//               Please provide a valid city.
//             </Form.Control.Feedback>
//           </Form.Group>
//         </Form.Row>

//         <Form.Row>
//           <Form.Group as={Col} md="4" controlId="validationCustom03">
//             <Form.Label>City</Form.Label>
//             <Form.Control type="text" placeholder="City" required />
//             <Form.Control.Feedback type="invalid">
//               Please provide a valid city.
//             </Form.Control.Feedback>
//           </Form.Group>
//         </Form.Row>

//         <Button type="submit">Submit form</Button>
//       </Form>
//     );
//   }

// export default Contact;

// import React, { useState } from "react";
// import axios from "axios";
// import { Formik, Form, Field, ErrorMessage } from "formik";
// import * as Yup from "yup";

// const formSchema = Yup.object().shape({
//     email: Yup.string()
//         .email("Invalid email")
//         .required("Required"),
//     message: Yup.string().required("Required")
// });

// export default () => {
//     /* Server State Handling */
//     const [serverState, setServerState] = useState();
//     const handleServerResponse = (ok, msg) => {
//         setServerState({ ok, msg });
//     };
//     const handleOnSubmit = (values, actions) => {
//         axios({
//             method: "POST",
//             url: "http://formspree.io/YOUR_FORM_ID",
//             data: values
//         })
//             .then(response => {
//                 actions.setSubmitting(false);
//                 actions.resetForm();
//                 handleServerResponse(true, "Thanks!");
//             })
//             .catch(error => {
//                 actions.setSubmitting(false);
//                 handleServerResponse(false, error.response.data.error);
//             });
//     };
//     return (
//         <div>
//             <h1>Contact Us</h1>
//             <Formik
//                 initialValues={{ email: "", message: "" }}
//                 onSubmit={handleOnSubmit}
//                 validationSchema={formSchema}
//             >
//                 {({ isSubmitting }) => (
//                     <Form id="fs-frm" noValidate>
//                         <label htmlFor="email">Email:</label>
//                         <Field id="email" type="email" name="email" />
//                         <ErrorMessage name="email" className="errorMsg" component="p" />
//                         <label htmlFor="message">Message:</label>
//                         <Field id="message" name="message" component="textarea" />
//                         <ErrorMessage name="message" className="errorMsg" component="p" />
//                         <button type="submit" disabled={isSubmitting}>
//                             Submit
//                 </button>
//                         {serverState && (
//                             <p className={!serverState.ok ? "errorMsg" : ""}>
//                                 {serverState.msg}
//                             </p>
//                         )}
//                     </Form>
//                 )}
//             </Formik>
//         </div>
//     );
// };
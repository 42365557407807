import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ListGroup, Form, InputGroup, Pagination, Navbar} from 'react-bootstrap';
//import cities from './city_info';
//import 'react-bootstrap-typeahead/css/Typeahead.css';
import surahList from './surahList';
// import quranLogo from './Quran2.png';
import quran from './quran_all';
import QuranAudio from './QuranAudio';

class Quran extends React.Component {
    constructor(props) {
        super(props);
        var selectedSurah = localStorage.getItem("selectedSurah") !== null ? parseInt(localStorage.getItem("selectedSurah")) : 1
        var initialText = quran.filter(i => i.surah === selectedSurah);
        initialText = (this.props.location.state)  != undefined ? this.props.location.state.quranText: initialText
        this.state = {
            isLoaded: false,
            selectedSurah: selectedSurah,
            selectedLanguage: localStorage.getItem("selectedLanguage") !== null ? localStorage.getItem("selectedLanguage") : "ENAR",
            urls: this.buildURL(initialText),
            activeAyah: this.createInitialActive(initialText.length),
            quranText: initialText

        };
        this.handleSurahChange = this.handleSurahChange.bind(this);
        this.handleButtonClick = this.handleButtonClick.bind(this);
        this.handler = this.handler.bind(this);
        this.handleLanguageChange = this.handleLanguageChange.bind(this);
        this.activeElement = React.createRef();
    }

    createInitialActive(sizeOfArray) {
        var initialArray = [];
        initialArray[0] = 1;
        for (var i = 1; i < sizeOfArray; i++) {
            initialArray[i] = 0;
        }
        return initialArray;
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        document.title = "Muslim Life - Quran";
        //this.getArabicQuran();
        //this.getArabicQuran();
    }

    // componentDidUpdate(prevProps) {
    //     // Typical usage (don't forget to compare props):
    //     // if (this.props.userID !== prevProps.userID) {
    //     //   this.fetchData(this.props.userID);
    //     // }
    //     if (prevProps.location.pathname != this.props.location.pathname) {

    //     }
    //     console.log("Inside did update....");
    //   }

    // getArabicQuran() {
    //     fetch('https://s3-us-west-2.amazonaws.com/www.realislam.team/quran/text/arabic/quran-arabic.txt')
    //     .then(res => res.text())
    //     .then(
    //       (arabicQuran) => {
    //     //     this.setState({
    //     //       cities: cityInfo
    //     //     });
    //     //     localStorage.setItem("cities2", JSON.stringify(cityInfo));
    //     var verses = arabicQuran.split(/[\r\n]+/);
    //     var translateQuran = quran;
    //     var prev = 0
    //     var index = 0
    //     var localQuran = []
    //     for (var i=0; i<quran.length; i++) {
    //         var localRecord = quran[i]
    //         if (localRecord.text === "In the name of Allah, the Beneficent, the Merciful.") {
    //             //add bismallah
    //             localRecord.arabic = "بِسْمِ اللَّهِ الرَّحْمَنِ الرَّحِيمِ"
    //         }
    //         else {
    //             //add our arabic verse and increase the count by one
    //             var record = verses[index].split('|')
    //             localRecord.arabic = record[2]
    //             index+=1
    //         }
    //         localQuran.push(localRecord)
            
    //         // if (parseInt(record[0]) != prev && parseInt(record[0]) != 9) {
    //         //     var row = {"surah" : parseInt(record[0]), "line" : 1, "text" : "بِسْمِ اللَّهِ الرَّحْمَنِ الرَّحِيمِ"}
    //         //     quran.push(row)
    //         //     prev = parseInt(record[0])
    //         // }
    //         // if (parseInt(record[0]) != 9) {
    //         //     var row = {"surah" : parseInt(record[0]), "line" : parseInt(record[1])+1, "text" : record[2]}
    //         //     quran.push(row)
    //         // }
    //         // else {
    //         //     var row = {"surah" : parseInt(record[0]), "line" : parseInt(record[1]), "text" : record[2]}
    //         //     quran.push(row)
    //         // }
        
    //     }
        
    //     //return localQuran;
    //     localStorage.setItem("asjnfsdfnaf", JSON.stringify(localQuran));
        
    //     // this.setState({
    //     //     // selectedSurah: this.state.selectedSurah + -1,
    //     //     // urls: this.buildURL(this.state.selectedSurah + -1, quranText.length),
    //     //     // activeAyah: this.createInitialActive(quranText.length),
    //     //     allText: JSON.stringify(localQuran)
    //     //   });

    //       })
    //     .catch(error => console.log(error));
    // }

    buildURL(quranText) {
        var urls = [];
        for (var i = 0; i <quranText.length; i++) {
            var index = quranText[i].surah;
            var line = quranText[i].line;
            if (index == 9) { //surah taubah
                line+=1;
            }

            urls.push(`https://s3-us-west-2.amazonaws.com/www.realislam.team/quran/arabic/${this.padThree(index)}${this.padThree(line-1)}.mp3`)
        }

        return urls;
    }

    handleButtonClick(event, val) {

        if (val == -1) {
            if (this.state.selectedSurah > 1) {
                var quranText = quran.filter(i => i.surah === this.state.selectedSurah + -1);
                localStorage.setItem("selectedSurah", this.state.selectedSurah + -1)
                this.setState({
                    selectedSurah: this.state.selectedSurah + -1,
                    urls: this.buildURL(quranText),
                    activeAyah: this.createInitialActive(quranText.length),
                    quranText: quranText
                  });
            }
        }
        else {
            if (this.state.selectedSurah < surahList.length) {
                var quranText = quran.filter(i => i.surah === this.state.selectedSurah + 1);
                localStorage.setItem("selectedSurah", this.state.selectedSurah + 1)
                this.setState({
                    selectedSurah: this.state.selectedSurah + 1,
                    urls: this.buildURL(quranText),
                    activeAyah: this.createInitialActive(quranText.length),
                    quranText: quranText
                  });
            }
        }
    }

    handleSurahChange(event) {
        const selectedSurah = parseInt(event.target.value);
        const quranText = quran.filter(i => i.surah === selectedSurah)
        localStorage.setItem("selectedSurah", selectedSurah)

        this.setState({
            selectedSurah: selectedSurah,
            urls: this.buildURL(quranText),
            activeAyah: this.createInitialActive(quranText.length),
            quranText: quranText
          });
    }

    handleLanguageChange(event) {
        const selectedLang = `${event.target.value}`;
        localStorage.setItem("selectedLanguage", selectedLang)
        
        this.setState({
            selectedLanguage: selectedLang
          });
    }

    handler(activeAyah) {
        window.scrollTo(0, this.activeElement.current.offsetTop)
        this.setState(
            { activeAyah: activeAyah }
        );
        
    }

    padThree(number) {
        if (number<=100) { number = ("000"+number).slice(-3); }
        return number;
      }

    render() {
        let quranRender;
        var prev = -99;
        var useTitle = [];

        for (var i = 0; i < this.state.quranText.length; i++) {
            if (this.state.quranText[i].surah !== prev) {
                prev = this.state.quranText[i].surah;
                useTitle.push((<h3 style={{textAlign: "center"}}>{surahList[prev-1]}</h3>));
            }
            else {
                useTitle.push((<h3></h3>));
            }
        }
        if (this.state.selectedLanguage === "ENAR") {
            quranRender = ( <ListGroup variant="flush">
                        {this.state.quranText.map((record, index) => (
                            <div key = {`titleDynamic${index}`}>
                                {useTitle[index]}
                            <ListGroup.Item 
                            key={index} 
                            variant={this.state.activeAyah[index] == 1 ? "success" : ""}
                            ref={this.state.activeAyah[index] == 1 ? this.activeElement : ""}
                        >{record.line}. {record.text} <div style={{textAlign: "right"}}>
                            <br></br>
                            {record.arabic}</div>
                            </ListGroup.Item>
                            </div>
                        ))}
                    </ListGroup>
            );
        } 
        else if (this.state.selectedLanguage === "AR") {
            quranRender = ( <ListGroup variant="flush" style={{textAlign: "right"}}>
                        {this.state.quranText.map((record, index) => (
                                 <div key = {`titleDynamic${index}`}>
                                {useTitle[index]}
                            
                            <ListGroup.Item 
                            key={index} 
                            variant={this.state.activeAyah[index] == 1 ? "success" : ""}
                            ref={this.state.activeAyah[index] == 1 ? this.activeElement : ""}
                            ><div>{record.arabic} .{record.line}</div>
                            
                            </ListGroup.Item>
                            </div>
                        ))}
                    </ListGroup>
            );
        }
        else {
            quranRender = ( <ListGroup variant="flush">
                        {this.state.quranText.map((record, index) => (
                             <div key = {`titleDynamic${index}`}>
                             {useTitle[index]}
                            <ListGroup.Item 
                            key={index} 
                            variant={this.state.activeAyah[index] == 1 ? "success" : ""}
                            ref={this.state.activeAyah[index] == 1 ? this.activeElement : ""}
                        >{record.line}. {record.text}
                            </ListGroup.Item>
                            </div>
                        ))}
                    </ListGroup> 
                    );
        }
        let visible = this.props.location.state != undefined ? "none" : "";
        return (
            <div >
                
                <div className="container" style={{ padding: '15px', paddingBottom: '100px'}}>{quranRender}</div>

                {/* <img src={quranLogo} alt="Logo" style={{display: "block", marginLeft: "auto", marginRight: "auto"}} /> */}
                <Navbar fixed="bottom" variant="dark" bg="dark" style={{ display: 'inline-block', textAlign: "center"}}>
                <Form>
                    <InputGroup>
                        <Form.Control style={{"display": visible}} as="select" size="md" value={this.state.selectedSurah} 
                         onChange={this.handleSurahChange} custom>
                            {surahList.map((chapter, index) => (
                                <option value={index + 1} key={index}>{chapter}</option>
                            ))}
                        </Form.Control>

                      
                        <Form.Control as="select" size="md" value={this.state.selectedLanguage} 
                         onChange={this.handleLanguageChange} custom>
                            <option value="ENAR" key="1Lang">English/Arabic</option>
                            <option value="EN" key="2Lang">English</option>
                            <option value="AR" key="3Lang">Arabic</option>
                        </Form.Control>
                   

                        <InputGroup.Append>
                            {/* <Button onClick={e => this.handleButtonClick(e, -1)} variant="outline-secondary">{"<"}</Button> */}
                        <Pagination>
                            <Pagination.Prev style={{"display": visible}} onClick={e => this.handleButtonClick(e, -1)} />
                            <Pagination.Next style={{"display": visible}} onClick={e => this.handleButtonClick(e, 1)} />
                            <QuranAudio urls={this.state.urls} activeAyahs={this.state.activeAyah} action={this.handler}></QuranAudio>
                            </Pagination>
                        </InputGroup.Append>

                        {/* <InputGroup.Append>
                            <Button  variant="outline-secondary">{">"}</Button>
                        </InputGroup.Append>
                     */}

                        {/* <InputGroup.Append>
                        <QuranAudio urls={this.state.urls} activeAyahs={this.state.activeAyah} action={this.handler}></QuranAudio>
                        </InputGroup.Append> */}
                    
                    </InputGroup>
                </Form>
                </Navbar>
                
            </div>
        )
    }
}

export default Quran;
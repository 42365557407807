import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Table } from 'react-bootstrap';
//import cities from './city_info';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { prayTimes } from './prayertimes';

class Day extends React.Component {
  constructor(props) {
    super(props);
    var tzlookup = require("tz-lookup");
    var tzone = tzlookup(this.props.lat, this.props.long);
    var calcMethod = localStorage.getItem("calcMethod") !== null ? localStorage.getItem("calcMethod") : "Jafari";
    var minusTime = (calcMethod == "Jafari") ? 86400000 : 0;
    var islamicDate = new Intl.DateTimeFormat('en-IQ-u-ca-islamic', 
    {day: 'numeric', month: 'long',year : 'numeric'}).format(Date.now() - minusTime);

    this.state = {
      autocomple: {
        disabled: false,
        dropup: false,
        flip: false,
        highlightOnlyResult: false,
        minLength: 3,
        open: undefined,
        selectHintOnEnter: false
      },
      loading: true,
      times: [],
      timezoneLookup: tzlookup,
      lat: this.props.lat,
      long: this.props.long,
      calcMethod: calcMethod,
      asrMethod: localStorage.getItem("asrMethod") !== null ? localStorage.getItem("asrMethod") : "Standard",
      date: new Date(),
      timezone: tzone,
      islamicDate: islamicDate,
      hrsOffUTC: this.getUTCOffset(tzone)
    };
    this.handleCalcChange = this.handleCalcChange.bind(this);
    this.handleAsrChange = this.handleAsrChange.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }

  handleCalcChange(event) {
    var minusTime = (event.target.value == "Jafari") ? 86400000 : 0;
    var islamicDate = new Intl.DateTimeFormat('en-IQ-u-ca-islamic', 
    {day: 'numeric', month: 'long',year : 'numeric'}).format(Date.now() - minusTime);

    localStorage.setItem("calcMethod", event.target.value);
    this.setState(
      { calcMethod: event.target.value, islamicDate : islamicDate },
      () => this.showPosition()
    );
  }

  handleAsrChange(event) {
    localStorage.setItem("asrMethod", event.target.value);
    this.setState(
      { asrMethod: event.target.value },
      () => this.showPosition()
    );
  }

  handleSelect = (e) => {

    if (e !== null && e !== "" && this.props.cities[e]) {
      var latLong = (this.props.cities[e]).split(';');
      var lat = parseFloat(latLong[0]);
      var long = parseFloat(latLong[1]);
      var timezone = this.state.timezoneLookup(lat,long);
      var localTime = new Date().toLocaleString("en-US", { timeZone: timezone });
      var localDate = new Date(localTime);
      var hrsOffUTC = this.getUTCOffset(timezone);
      this.setState(
        { timezone: timezone, date: localDate, hrsOffUTC: hrsOffUTC, lat: lat, long: long },
        () => this.showPosition()
      );
    }

  }

  componentDidMount() {
    window.scrollTo(0, 0);
    document.title = "Muslim Life - Daily Prayer Times";
    if (this.props.lat != '' && this.props.long != '') {
      this.showPosition();
    }
    else {
      alert("Please enable location to automatically retrieve prayer times for your area.");
    }
  }

  render() {
    return (
      <div>
        <div className="container" style={{ padding: '15px'}}>
          {/* {this.state.hidden &&
            <div className="text-center">
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            </div>
          } */}
          <div style={{ textAlign: 'center'}}><h2>Prayer Times 🕘</h2></div>
        <div style={{ textAlign: 'center'}}><a>{this.state.islamicDate}</a></div>
          <Table striped bordered hover>
            <thead className="thead-light">
              <tr>
                <th scope="col">Time of Day</th>
                <th scope="col">Time</th>
              </tr>
            </thead>
            <tbody id="table">
              {this.state.times.map((item, idx) => (
                <tr key={`record${idx}`}>
                  <td>{this.state.times[idx].name}</td>
                  <td>{this.state.times[idx].time}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          <form>
            <div className="form-group">
              <label htmlFor="cityText">City</label>
              <Typeahead
                {...this.state.autocomple}
                id="city"
                onChange={this.handleSelect}
                options={Object.keys(this.props.cities)}
                defaultInputValue={this.props.cityName}
                placeholder="City"
              />
            </div>
            <div className="form-group">
              <label htmlFor="method">Calculation Method</label>
              <select className="form-control" id="method" value={this.state.calcMethod} onChange={this.handleCalcChange}>
                <option value="Jafari">Jafari Shia Ithna Ashari</option>
                <option value="MWL">Muslim World League</option>
                <option value="ISNA">Islamic Society of North America</option>
                <option value="Egypt">Egyptian General Authority of Survey</option>
                <option value="Makkah">Umm al-Qura University, Makkah</option>
                <option value="Karachi">University of Islamic Sciences, Karachi</option>
                <option value="Tehran">Institute of Geophysics, University of Tehran</option>
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="asrMethod">Asr Method</label>
              <select className="form-control" id="asrMethod" value={this.state.asrMethod} onChange={this.handleAsrChange}>
                <option value="Standard">Jafari, Shafii, Maliki, and Hanbali</option>
                <option value="Hanafi">Hanafi</option>
              </select>
            </div>
          </form>
        </div>
      </div>
    );
  };



  getUTCOffset(timeZoneName) {
    var localTime = new Date().toLocaleString("en-GB", { timeZone: timeZoneName });
    var dateInfo = localTime.split(" ");

    var hour = parseInt(dateInfo[1].split(':')[0]);
    var UTCdate = new Date();
    var UTCHour = UTCdate.getUTCHours();

    return hour - UTCHour;

  }

  showPosition() {
    prayTimes.setMethod(this.state.calcMethod);
    prayTimes.adjust({ asr: this.state.asrMethod });

    var times = prayTimes.getTimes(this.state.date, [this.state.lat, this.state.long], this.state.hrsOffUTC, 0, '12h');
    var list = ['Imsak', 'Fajr', 'Sunrise', 'Dhuhr', 'Asr', 'Sunset', 'Maghrib', 'Isha', 'Midnight'];
    
    var prayers = [];
    for (var i in list) {
      var dayTime = {
        'name': list[i],
        'time': times[list[i].toLowerCase()]
      }
      prayers.push(dayTime);
    }

    this.setState(function (prevState, props) {
      return { times: prayers, loading: false }
    });

  }
};

export default Day;
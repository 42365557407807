import React from 'react';
import { Pagination } from "react-bootstrap"

class QuranAudio extends React.Component {
  constructor(props) {
    super(props);
    const initialAudio = new Audio();
    initialAudio.preload = "none";
    initialAudio.src = this.props.urls[0];
    this.state = {
      play: false,
      audio: initialAudio,
      index: 0
    }
    this.handleVerseComplete = this.handleVerseComplete.bind(this);
  }

  componentDidMount() {
    this.state.audio.addEventListener('ended', () => this.handleVerseComplete());
  }

  createActiveAyah(index) {
    var initialArray = [];
    for (var i = 0; i < this.props.urls.length; i++) {
      initialArray[i] = 0;
    }
    initialArray[index] = 1;
    return initialArray;
  }

  handleVerseComplete() {

    if (this.state.index < this.props.urls.length - 1) {
      this.state.audio.src = this.props.urls[this.state.index + 1];
      this.state.audio.play();

      this.props.action(this.createActiveAyah(this.state.index + 1));

      this.setState({
        index: this.state.index + 1
      });
    }
    else {
      this.props.action(this.createActiveAyah(0));
      this.state.audio.src = this.props.urls[0];
      this.setState({
        index: 0,
        play: false
      });
    }
  }

  componentDidUpdate(prevProps) {

    //two cases: play button was clicked or surah changed
    if (!this.arrMatch(prevProps.urls, this.props.urls)) {
      window.scrollTo(0, 0);
      if (this.state.audio.duration > 0 && !this.state.audio.paused) {
        this.state.audio.pause();
      }

      this.state.audio.src = this.props.urls[0];

      this.setState({
        index: 0,
        play: false
      });
    }
  }

  arrMatch(arr1, arr2) {

    // Check if the arrays are the same length
    if (arr1.length !== arr2.length) return false;

    // Check if all items exist and are in the same order
    for (var i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) return false;
    }

    // Otherwise, return true
    return true;

  };

  componentWillUnmount() {
    this.state.audio.pause();
    this.state.audio.removeEventListener('ended', () => this.setState({ play: false }));
  }

  togglePlay = (e) => {
    e.preventDefault();
    this.setState({ play: !this.state.play }, () => {
      this.state.play ? this.state.audio.play() : this.state.audio.pause();
    });
  }

  render() {
    return (
      <Pagination.Item onClick={this.togglePlay}>{this.state.play ? "Pause ⏸️" : "Play ▶"}</Pagination.Item>
    );
  }
}

export default QuranAudio;
import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Day from './Day';
import Error from './Error';
import Header from './Header';
import Mosque from './Mosque';
import stateMap from './stateMap';
//import cities from './city_info';
import Food from './Food';
import Quran from './Quran';
import QuranSchedule from './QuranSchedule';
import Contact from './Contact';

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      lat: '',
      long: '',
      cityName: '',
      isLoaded: false,
      cities: {}
    };
    this.getCityState = this.getCityState.bind(this);
    this.error = this.error.bind(this);
    this.getCityInfo = this.getCityInfo.bind(this);
    this.getNearestCity = this.getNearestCity.bind(this);
    this.getUserLocation = this.getUserLocation.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getCityInfo()
    .then(() => {
      let search = window.location.search;
      let params = new URLSearchParams(search);
      let city = params.get('city');
      if (city && city in this.state.cities) {
        var locationInfo = this.state.cities[city].split(';');
        const lat = parseFloat(locationInfo[0]);
        const lon = parseFloat(locationInfo[1]);
        this.setState({
          isLoaded: true,
          cityName: city,
          lat: lat,
          long: lon
        });
      }
      else {
        var options = {
          enableHighAccuracy: true,
          timeout: 7000,
          maximumAge: 0
        };
        navigator.geolocation.getCurrentPosition(this.getUserLocation, this.getCityState, options);
      }
    })
  }

  getCityInfo() {
    return new Promise((resolve, reject) => {
      if (Object.keys(this.state.cities).length === 0 && localStorage.getItem("cities2") === null) {
        fetch('https://s3-us-west-2.amazonaws.com/www.realislam.team/cities.json')
          .then(res => res.json())
          .then(
            (cityInfo) => {
              this.setState({
                cities: cityInfo
              });
              localStorage.setItem("cities2", JSON.stringify(cityInfo));
            })
          .catch(error => console.log(error));
      }
      else if (Object.keys(this.state.cities).length === 0 && localStorage.getItem("cities2") !== null) {
        var cityDetails = JSON.parse(localStorage.getItem("cities2"));
        this.setState({
          cities: cityDetails
        });
      }
      resolve("Promise resolved");
    });
  }

  getCityState() {
    fetch(`https://api.freegeoip.app/json/?apikey=ee5dd2e0-b479-11ec-8e6a-f3d3a0c31014`)
      .then(res => res.json())
      .then(
        (locationInfo) => {
          if (!locationInfo.city || !locationInfo.regional_code) {
            var cityState = this.getNearestCity(locationInfo.latitude, locationInfo.longitude);
          }
          else {
            var stateName = locationInfo.address.state;
            if (stateMap[stateName.toUpperCase()]) {
              stateName = stateMap[stateName.toUpperCase()];
            }
            cityState = `${locationInfo.address.city}, ${stateName}`;
          }

          if (cityState) {
            this.setState({
              isLoaded: true,
              cityName: cityState,
              lat: locationInfo.latitude,
              long: locationInfo.longitude
            });
          }
          
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  getUserLocation(position) {
    fetch(`https://nominatim.openstreetmap.org/reverse?lat=${position.coords.latitude}&lon=${position.coords.longitude}&format=json`)
      .then(res => res.json())
      .then(
        (locationInfo) => {
          if (locationInfo.address.state == null || locationInfo.address.city == null) {
            var cityState = this.getNearestCity(position.coords.latitude, position.coords.longitude);
          }
          else {
            var stateName = locationInfo.address.state;
            if (stateMap[stateName.toUpperCase()]) {
              stateName = stateMap[stateName.toUpperCase()];
            }
            cityState = `${locationInfo.address.city}, ${stateName}`;
          }

          this.setState({
            isLoaded: true,
            cityName: cityState,
            lat: position.coords.latitude,
            long: position.coords.longitude
          });

        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  error(err) {
    console.warn(`ERROR(${err.code}): ${err.message}`);
    this.setState({
      isLoaded: true
    });
  }

  getNearestCity(lat, lng) {
    var minDistance = Number.MAX_SAFE_INTEGER;
    var cityName = "";
    for (var key of Object.keys(this.state.cities)) {
      var locationInfo = this.state.cities[key].split(';');
      var lat2 = parseFloat(locationInfo[0]);
      var lon2 = parseFloat(locationInfo[1]);
      var distance = this.distanceBetweenPoints(lat, lng, lat2, lon2);
      if (distance < minDistance) {
        minDistance = distance;
        cityName = key;
      }
    }
    return cityName;
  }

  distanceBetweenPoints(lat1, lon1, lat2, lon2) {
    var p = 0.017453292519943295;    // Math.PI / 180
    var c = Math.cos;
    var a = 0.5 - c((lat2 - lat1) * p) / 2 +
      c(lat1 * p) * c(lat2 * p) *
      (1 - c((lon2 - lon1) * p)) / 2;

    return 12742 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km
  }

  render() {
    if (!this.state.isLoaded)
      return (
        <BrowserRouter>
          <div>
            <Header />
            <div id="center" style={{ position: 'fixed', top: '50%', left: '50%' }}>
              <div className="spinner-border" role="status" id="loader">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        </BrowserRouter>
      );
    return (
      <BrowserRouter>
        <div>
          <Header />
          <Switch>
            <Route
              path='/'
              render={(props) => <Day {...props} lat={this.state.lat} long={this.state.long} cities={this.state.cities} cityName={this.state.cityName} />}
              exact />
            <Route
              path='/mosques'
              render={(props) => <Mosque {...props} lat={this.state.lat} long={this.state.long} type="mosque" />}
              exact />
            <Route
              path='/halalfood'
              render={(props) => <Food {...props} lat={this.state.lat} long={this.state.long} type="halal" target="_self" />}
              exact />
            <Route
              path='/quran'
              render={(props) => <Quran key="1" {...props} target="_self" />}
              exact />
            <Route
              path='/quranschedule/:day'
              render={(props) => <Quran key="2" {...props} target="_self" />}
              exact />
            <Route
              path='/quranschedule'
              render={(props) => <QuranSchedule {...props} target="_self" />}
              exact />
            <Route
              path='/contact'
              render={(props) => <Contact {...props} target="_self" />}
              exact />
            <Route component={Error} />
          </Switch>
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
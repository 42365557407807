import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {FormGroup, Button } from 'react-bootstrap';
import InputGroup from 'react-bootstrap/InputGroup'
//import cities from './city_info';
import 'react-bootstrap-typeahead/css/Typeahead.css';
//import surahList from './surahList';
// import quranLogo from './Quran2.png';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Link } from 'react-router-dom'
import quran from './quran_all';


class QuranSchedule extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            autocomple: {
                disabled: false,
                dropup: false,
                flip: false,
                highlightOnlyResult: false,
                minLength: 1,
                open: undefined,
                selectHintOnEnter: false
            },
            isLoaded: false,
            days: localStorage.getItem("numDays") !== null ? localStorage.getItem("numDays") : 30,
            partition: [],
            progress: localStorage.getItem("progress") !== null ? JSON.parse(localStorage.getItem("progress")) : {},
        };
        this.partitionQuran = this.partitionQuran.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleLinkClick = this.handleLinkClick.bind(this);
        this.resetProgress = this.resetProgress.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        document.title = "Muslim Life - Quran Schedule";
        this.partitionQuran();
    }

    partitionQuran() {

        var i, j, temparray, chunk = Math.floor(quran.length / this.state.days);
        var partitions = []
        for (i = 0, j = quran.length; i < j; i += chunk) {
            temparray = quran.slice(i, i + chunk);
            partitions.push(temparray);
        }

        if (partitions.length > this.state.days) {
            for (i = this.state.days; i < partitions.length; i++) {
                partitions[this.state.days - 1] = partitions[this.state.days - 1].concat(partitions[i]);
            }
            partitions.splice(this.state.days, partitions.length);
        }

        this.setState({
            partition: partitions
        });
    }

    handleChange = (e) => {
        if (e !== undefined && e !== null && e !== "" && e.length > 0) {
            const days = parseInt(e[0].split(" ")[0]);
            localStorage.setItem("numDays", days);
            this.setState(
                { days: days, progress: {} },
                () => this.partitionQuran()
            );
        }
    }

    handleLinkClick(e, id) {
        this.state.progress[`${id}`] = "Complete ✅"
        //var progress = this.state.progress;
        localStorage.setItem("progress", JSON.stringify(this.state.progress));
        // if (Object.keys(this.state.progress).length == this.state.days) {
        //     alert(`Way to go! You completed the Quran in ${} days. InshAllah you are given the strength to continue reciting.`)
        // }
        this.setState({
            progress: this.state.progress
        })
    }

    resetProgress() {
        localStorage.setItem("progress", JSON.stringify({}));
        this.setState({
            progress: {}
        })
    }

    daysOfYear() {
        var days = []
        for (var i = 1; i < 366; i++) {
            days.push(`${i}`);
        }
        return days;
    }

    render() {
        return (
            <div id="myDiv" className="animate-bottom" style={{ padding: '15px', textAlign: 'center' }}>

                <h2>Quran Schedule 📖</h2>


                {/* <div>
                    <ListGroup style={{ textAlign: "center" }} variant="flush">
                    <ListGroup.Item style = {{"display" : this.state.selectedSurah === 9 ? "none" : ""}}>1. In the name of Allah, Most Gracious, Most Merciful.</ListGroup.Item>
                        {this.state.quranText.map((record, index) => (
                            <ListGroup.Item key={index}>{this.state.selectedSurah === 9 ? record.line : record.line + 1}. {record.text}</ListGroup.Item>
                        ))}
                    </ListGroup>
                </div> */}
                <div className="container" id="dailyInfo">

        
                        <FormGroup>
                            <InputGroup style={{textAlign: "center"}}>
                            
                                <Typeahead style={{ textAlign: 'center' }}
                                    {...this.state.autocomple}
                                    id="days"
                                    onChange={this.handleChange}
                                    options={this.daysOfYear()}
                                    defaultInputValue={`${this.state.days}`}
                                    placeholder="Number of days"

                                />
                                <InputGroup.Append>
                                <InputGroup.Text>Days</InputGroup.Text>
                                </InputGroup.Append>

                                <InputGroup.Append>
                                <Button onClick={this.resetProgress} variant="dark">Reset ❌</Button>
                                </InputGroup.Append>
                            </InputGroup>
                        </FormGroup>
         

                    {this.state.partition.map((item, idx) => (
                        <div className="card" key={idx}>
                            <div className="card-body">
                                <h4 className="card-title">
                                    <Link onClick={e => this.handleLinkClick(e, idx)} to={{
                                        pathname: `/quranschedule/:${idx+1}`,
                                        state: {
                                            quranText: item
                                        }
                                    }}>Day {idx + 1} {this.state.progress[`${idx}`]}</Link>
                                </h4>
                                {/* <p className="card-text">Rating: {this.state.locations[idx].rating} ⭐</p>
                                    <p className="card-text">Address: {this.state.locations[idx].address} 📍</p>
                                    <p className="card-text">Distance: {this.state.locations[idx].distance} 📏</p>
                                    <p className="card-text">Commute: {this.state.locations[idx].duration} 🚗</p>
                                    <a className="card-text" href={this.state.locations[idx].contact} target="_blank">Contact Information</a> */}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        )
    }
}

export default QuranSchedule;




    // <Form>
    //     <Form.Group controlId="exampleForm.SelectCustomSizeSm">
    //         {/* <label htmlFor="days">City</label> */}
    //         <div style={{ alignContent: "center" }}>
    //             <Typeahead style={{ textAlign: 'center' }}
    //                 {...this.state.autocomple}
    //                 id="days"
    //                 onChange={this.handleChange}
    //                 options={this.daysOfYear()}
    //                 defaultInputValue={"30 Days"}
    //                 placeholder="Enter number of days to read the quran"

    //             />
    //         </div>
    //     </Form.Group>
    // </Form>